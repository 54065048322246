import React from "react";
import { graphql } from "gatsby";
import cx from "classnames";

import theme from "../constants/theme";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { ContentPage, CTASection } from "../components/blocks";
import { Wrapper } from "../components/core";

export default function blogPostTemplate(props) {
  const siteMetaData = props.data.site.siteMetadata;
  const siteData = props.data.allSanitySiteConfig.edges[0].node;
  const sharedData = props.data.allSanitySharedSections.edges[0].node;
  const pageData = props.data.sanityBlogPost;

  return (
    <Layout
      locale={props.pageContext.locale}
      siteMetaData={siteMetaData}
      mainNav={siteData.mainNav}
      alternateNav={siteData.alternateNav}>
      <SEO title={pageData.title} />
      <ContentPage
        title={pageData.title}
        blocks={pageData._rawContent ?? []}
        coverImageUrl={pageData.coverImage?.asset?.fluid?.src}
        date={pageData.publishDate}
        author={pageData.author?.name}
        category={pageData.category?.title}
      />
      <CTASection
        title={sharedData.callToActionSection?.title?.localized}
        description={sharedData.callToActionSection?.description?.localized}
        callToActionTitle={sharedData.callToActionSection?.callToAction?.linkTitle?.localized}
        callToActionRoute={sharedData.callToActionSection?.callToAction?.route?.slug?.current}
      />
    </Layout>
  );
}

import * as _ from "./fragments";

export const query = graphql`
  query($locale: String!, $id: String!) {
    # Site Meta Data
    site {
      ...SiteMetaData
    }
    # Site
    allSanitySiteConfig {
      edges {
        node {
          ...MainNav
          ...AlternateNav
        }
      }
    }
    # Shared
    allSanitySharedSections {
      edges {
        node {
          ...CallToActionSection
        }
      }
    }
    # Page
    sanityBlogPost(id: { eq: $id }) {
      title
      publishDate(formatString: "LL", locale: $locale)
      coverImage {
        ...Image
      }
      author {
        name
        profileImage {
          ...Image
        }
      }
      category {
        title
      }
      _rawContent
    }
  }
`;
